import * as types from "../actions/action_types";

const initialState = {
  fetching: false,
  fetched: false,
  points: [],
  poly: "",
  error: null
};

const tourFullPolyline = function(state = initialState, action) {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return initialState;
    case types.TOUR_FULL_DATA_POLYLINE_PENDING:
      return { ...initialState, fetching: true };
    case types.TOUR_FULL_DATA_POLYLINE_REJECTED:
      return { ...state, fetching: false, error: action.payload };
    case types.TOUR_FULL_DATA_POLYLINE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        points: action.payload.points,
        poly: action.payload.poly,
      };
  }
  return state;
};

export default tourFullPolyline ;
