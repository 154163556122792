import * as types from '../actions/action_types';
import { SEARCH_BAR_ALL, SEARCH_DEFAULT_ROAD_TYPE } from '../constants/mainSearch.constants';

const initialState = {
    scope: SEARCH_BAR_ALL,
    roadTypes: SEARCH_DEFAULT_ROAD_TYPE,
}

const searchBarData = (state=initialState,action) => {
    switch (action.type){
        case types.SEARCH_BAR_SCOPE:
            return { ...state, scope: action.payload};
        case types.SEARCH_BAR_ROAD_TYPES:
            return { ...state, roadTypes: action.payload};
        default:
            return state
    }
}
export default searchBarData;