import * as types from '../actions/action_types';

const initialState = {
  price: {},
  selectedCurrency: '',
  voucher: {},
};

const tourstartProData = (state = initialState, action) => {
  switch (action.type) {
    case types.PRO_DATA_INIT:
      return {
        ...state,
        voucher: {},
        finished: false,
        paymentId: '',
      };
    case types.PRO_DATA_PRICE:
      return { ...state, price: action.payload };
    case types.PRO_DATA_CHANGE_SELECTED_CURRENCY:
      return { ...state, selectedCurrency: action.payload };
    case types.PRO_DATA_CHANGE_VOUCHER_DATA:
      return { ...state, voucher: action.payload };
    default:
      return state;
  }
};
export default tourstartProData;
